import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withHashLocation, withRouterConfig } from '@angular/router';
  
import { routes } from './app.routes';

import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
  
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { interceptorInterceptor } from './shared/interceptador/interceptor.interceptor';
   
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch(), withInterceptors([interceptorInterceptor])),
    provideRouter(routes, withHashLocation()),
    provideEnvironmentNgxMask(), 
    provideClientHydration(), 
    provideAnimations(),
    provideToastr(
      {
          timeOut: 5000,
          positionClass: 'toast-bottom-right',
          preventDuplicates: true,
          progressBar: true,
      }
    ),
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ]
};