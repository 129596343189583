/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { AppConfig } from './app/shared/classes/app.config';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');

if (environment.production) {
  enableProdMode();
  AppConfig.definirUrlLRApi('https://api-gestor.rematefy.com/api/v1')
  AppConfig.definirUrlLRApiRfy('https://api.rematefy.com/')
  AppConfig.definirUrlSig('https://socket.rematefy.com')
} else {
  AppConfig.definirUrlLRApi('https://api-gestor-hml.rematefy.com/api/v1')
  AppConfig.definirUrlLRApiRfy('https://api-hml.rematefy.com')
  AppConfig.definirUrlSig('https://socket-server-rfy-hml.azurewebsites.net')
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
