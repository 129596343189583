import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'Web-Gestor';

    constructor(
        private router: Router,
        private authS: AuthService,
    ) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e.url === '/' || e.url === '/login') {
                    this.router.navigate(['/dashboard'])
                }
            }
        })
    }

    ngOnInit(): void {
        this.authS.renovarToken()
    }
}
