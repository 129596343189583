import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guardas/auth.guard';

export const routes: Routes = [

    {
        path: '',
        loadChildren: () => import('./paginas/paginas.module').then(m => m.PaginasModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'testes-internos',
        loadChildren: () => import('./testes-internos/testes-internos.module').then(m => m.TestesInternosModule)
    },
    { 
        path: '**', 
        redirectTo: 'dashboard'
    },

]